import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'add-credit',
    loadComponent: () => import('./add-credit/add-credit.page').then((m) => m.AddCreditPage),
  },
  {
    path: 'error',
    loadComponent: () => import('./error/error.page').then((m) => m.ErrorPage),
  },
  {
    path: 'success',
    loadComponent: () => import('./success/success.page').then((m) => m.SuccessPage),
  },
  {
    path: 'quotation',
    loadComponent: () => import('./quotation/quotation.page').then((m) => m.QuotationPage),
  },
  {
    path: '',
    redirectTo: 'add-credit',
    pathMatch: 'full',
  },
  {
    path: 'withdraw',
    loadComponent: () => import('./withdraw/withdraw.page').then( m => m.WithdrawPage)
  },
  {
    path: 'withdraw-success',
    loadComponent: () => import('./withdraw-success/withdraw-success.page').then( m => m.WithdrawSuccessPage)
  }
];
